import React from 'react'

const ToolkitItem = ({ heading, content }) => {
    return (
        <div className='flex-1 flex flex-col items-center justify-center bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] p-6 lg:p-10'>
            <div>
                <h3 className='h3 w-fit pb-5 gradient-text'>{heading}</h3>
                <p className='p'>{content}</p>
            </div>
        </div>
    )
}

export default ToolkitItem
