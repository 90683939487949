import React from 'react'
import ProductItem from './ProductItem'

const Products = () => {
    const products = [
        {
            heading: 'kazLEAP Educator Support Software',
            content: `Empower educators by streamlining administrative tasks and reducing
                      screen time, allowing them to focus on nurturing young minds. kazLEAP
                      automates routine processes, enhancing overall efficiency in education delivery.`,
            imageFile: 'kazleap-logo.svg',
            alt: 'kazLEAP Logo with 3 Kangaroos',
            link: 'https://kazleap.com/'
        },
        {
            heading: 'Codeflix and Deadly Coders',
            content: `AI-powered platforms designed to inspire and train the next generation of engineers.
                      These platforms provide STEM training in various computer languages to school-aged children,
                      including specialised programs for indigenous students. Personalised AI tutors guide children
                      through courses, offering customised quizzes and progress reports to maximise learning.`,
            imageFile: 'deadlycoders-logo.svg',
            alt: 'DEADLYCODERS Logo',
            link: ''
        },
        {
            heading: 'Magic Story Builder',
            content: `Our AI-powered educational toy fosters cognitive development through interactive storytelling.
                      Children craft their own stories using tokens representing characters, locations, and genres.
                      These stories are narrated using custom AI voices, making learning both fun and developmental
                      while minimising screen time.`,
            imageFile: 'magic-story-builder-logo-cropped.png',
            alt: 'Magic Story Builder Logo',
            link: ''
        },
    ]
    return (
        <section className="container-small relative mb-[90px] md:mb-[120px]" id="products">
            <h1 className='h1-large text-center mb-10'>Our Products</h1>
            <div className='flex flex-col bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px]'>
                {products.map((product, index) => (
                    <ProductItem key={index} heading={product.heading} content={product.content} imageFile={product.imageFile} alt={product.alt} link={product.link}/>
                ))}
            </div>
        </section>
    )
}

export default Products
