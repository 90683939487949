import React from 'react'

const Footer = () => {
    return (
        <div className='container'>
            <footer className="bg-kaz-blue bg-opacity-3 pl-8 pr-8 sm:pr-4 py-3 flex gap-[20px] items-center relative border-2 border-solid border-kaz-blue border-opacity-40 rounded-full">
                <div className="flex-1">
                    <img src="/images/kazacosai-logo-gradient.png" alt="Kazacos AI Logo" className="w-[110px] h-auto" />
                </div>
                <p className='p-small flex-1 text-right sm:text-center'>&copy; 2023 - 2024 Kazacos AI. All rights reserved.</p>
                <div className='flex-1 justify-end hidden sm:flex'>
                    <a href="#contact-us">
                        <button className='btn-small btn-primary md:mr-0'>Contact Us</button>
                    </a>
                </div>
            </footer>
        </div>
    )
}

export default Footer
