import React from 'react'

const GlowBackground = () => {
    const body = document.body
    const html = document.documentElement

    const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    let glowHeight = 0
    let counter = 1

    let glowArr = []
    let greenColoured = false

    while(glowHeight <= pageHeight) {
        glowArr.push(<div key={counter} className={`z-[-1] w-[330px] h-[330px] xsm:w-[350px] sm:h-[350px] md:w-[400px] md:h-[400px] lg:w-[450px] lg:h-[450px] absolute bg-opacity-60 rounded-full blur-[120px] ${greenColoured ? 'bg-kaz-green -right-[20rem]' : 'bg-kaz-blue -left-[20rem]'}`} style={{ top: `${glowHeight}px` }}/>)
        glowHeight += 650
        greenColoured = !greenColoured
        counter++
    }
    
    return (
        <div className='relative z-[0] w-full'>
            {glowArr}
        </div>
    )
}

export default GlowBackground
