import React from 'react'

const TeamItem = ({ name, title, image, linkedin = '' }) => {
    return (
        <div className='relative flex-1 flex flex-col items-center justify-center gap-3 h-[326.5px] bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] text-center min-w-[220px]'>
            <img src={`/images/${image}`} alt={`Portrait of ${name}`} className='w-[150px] h-[150px] rounded-full object-cover mb-3'/>
            <p className='p gradient-text font-bold w-fit whitespace-nowrap'>{name}</p>
            <p className='p'>{title}</p>
            { linkedin && 
                <a href={`https://www.linkedin.com/in/${linkedin}`} target="_blank" rel="noreferrer">
                    <div className='absolute flex items-center justify-center top-[20px] right-[20px] w-[40px] h-[40px] gradient-ellipse rounded-full'>
                        <img src='/images/linkedin-logo.png' alt='LinkedIn Logo' className='w-[30px] h-[30px]'/>
                    </div>
                </a>
            }
        </div>
    )
}

export default TeamItem