import React from 'react'
import ToolkitItem from './ToolkitItem'

const toolkitItems = [
    {
        heading: 'Unleash the Power of AI with KIST',
        content: `Elevate your business with the unmatched capabilities of the Kazacos Intelligence
                  System Toolkit (KIST). Engineered by the trailblazers at Kazacos AI, KIST is your
                  gateway to crafting AI-powered applications with exceptional speed and precision,
                  utilising robust technologies designed to meet your unique needs and deliver
                  innovative solutions.`,
    },
    {
        heading: 'What Sets KIST Apart? ',
        content: `Our proprietary prompting framework ensures unparalleled accuracy and dramatically
                 reduces AI hallucinations, providing you with reliable, top-tier performance
                 every time. The toolkit’s unique design offers complete independence from specific
                 Large Language Models (LLMs), giving you the flexibility to select the optimal LLM
                 tailored to your application’s needs.`,
    },
    {
        heading: 'Innovative Products that Redefine Standards',
        content: `Trusted and used exclusively by Kazacos AI developers, KIST is not just a set of tools—
                 it’s a revolution in AI application development integrating cutting-edge algorithms and
                 machine learning models to stay ahead of the competition. Experience the difference:
                 Empower your projects with KIST today and transform potential into performance.`,
    },
]

const Toolkit = () => {
    return (
        <section className="container relative mb-[90px] md:mb-[120px]" id="toolkit">
            <h2 className='h2 mb-6 text-center xsm:text-left xsm:pl-12'>Kazacos AI's Intelligence System Toolkit (KIST)</h2>
            <div className='flex flex-col gap-[20px]'>
                <div className='flex flex-col md:flex-row gap-[20px]'>
                    <ToolkitItem heading={toolkitItems[0].heading} content={toolkitItems[0].content}/>
                    <ToolkitItem heading={toolkitItems[1].heading} content={toolkitItems[1].content}/>
                </div>
                <div className='flex flex-col md:flex-row gap-[20px]'>
                    <ToolkitItem heading={toolkitItems[2].heading} content={toolkitItems[2].content}/>
                    <div className='flex-1 hidden md:flex flex-col items-center justify-center md:items-start pt-10 md:p-10'>
                        <h3 className='h3 w-fit pb-5 gradient-text text-center md:text-left'>Get Started with Kazacos AI Today</h3>
                        <a href="#contact-us">
                            <button className='btn btn-primary'>Make an Inquiry</button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Toolkit
