import React, { useState } from 'react'
import FaqItem from './FaqItem'

const Faq = () => {
    const [activeQuestion, setActiveQuestion] = useState('1')
    
    const faqs = [
        {
            question: 'How can I get started with Kazacos AI?',
            answer: `You can find the 'Make an Inquiry' button either at the top menu bar or at the
                     footer of the homepage. Click on this link to access our contact form. There is a
                     section in the form where you can type out the details of your inquiry. Whether it's
                     a question about our services, a request for a demo, or interest in a partnership,
                     feel free to provide as much detail as necessary.`,
        },
        {
            question: 'Where is Kazacos AI located?',
            answer: `We are an Australian AI-focused software development company located in Sydney.
                     All of our products are developed in-house in our Sydney offices.`,
        },
        {
            question: 'What development services do Kazacos AI offer?',
            answer: `We offer a variety of services including: consulting, education, and full product development.`,
        },
        {
            question: 'Which AI technologies are utilised by Kazacos AI?',
            answer: `We utilise a culmination of the latest and most innovative AI technologies and platforms, 
                     orchestrated using our unique KIST toolkit.`,
        },
        {
            question: 'What is the pricing for Kazacos AI\'s services?',
            answer: `We provide both fixed price and time-and-material work. Please contact us for more details.`,
        },
    ]

    return (
        <section className="container-small relative mb-[90px] md:mb-[120px]" id="faq">
            <h1 className='h1-large text-center mb-10'>FAQ</h1>
            <div>
                {faqs.map((faq, index) => (
                    <FaqItem key={index} questionNumber={`${index + 1}`} isActive={activeQuestion === `${index + 1}`} question={faq.question} answer={faq.answer} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion}/>
                ))}
            </div>
        </section>
    )
}

export default Faq
