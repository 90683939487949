import React from 'react'
import TeamItem from './TeamItem'

const Team = () => {
    const team = [
        {
            name: 'Peter Kazacos',
            title: 'Co-Founder & CEO',
            image: 'peter-image.png',
            linkedin: 'kazacospeter'
        },
        {
            name: 'Cameron Aume',
            title: 'Co-Founder & CTO',
            image: 'cameron-image.png',
            linkedin: 'cameron-aume-32a485b4'
        },
        {
            name: 'Crystal Xu',
            title: 'Executive Director',
            image: 'crystal-image.png',
            linkedin: 'crystal-x-2851b2181'
        },
        {
            name: 'Con Kazacos',
            title: 'Non-Executive Director',
            image: 'con-image.png',
        },
    ]
    
    return (
        <section className='container relative mb-[90px] md:mb-[120px]' id='team'>
            <h2 className='h2 mb-6 text-center xsm:text-left xsm:pl-12'>Our Board</h2>
            <div className='flex flex-wrap gap-[20px] items-center'>
                {team.map((member, index) => (
                    <TeamItem key={index} name={member.name} title={member.title} image={member.image} linkedin={member.linkedin} />
                ))}
            </div>
        </section>
    )
}

export default Team