import React from 'react'

const ProductItem = ({ heading, content, imageFile, alt, link }) => {
    return link ? (
        <a className='flex-1 flex flex-col-reverse md:flex-row items-center gap-7 md:gap-14 p-5 sm:p-10 border-b-2 border-solid border-kaz-blue border-opacity-40 transition-[background-color] duration-300 ease-in-out hover:bg-kaz-blue hover:bg-opacity-[0.15] first:rounded-t-[38px] last:rounded-b-[38px] last:border-b-0' href={link} target="_blank" rel="noreferrer">
            <div className='flex-1 flex flex-col justify-center'>
                <h3 className='h3 gradient-text w-fit pb-5'>{heading}</h3>
                <p className='p'>{content}</p>
            </div>
            <div className='flex justify-center bg-[white] rounded-[36px] w-full h-2/4 md:w-[225px] md:h-[150px] lg:w-[330px] lg:h-[220px]'>
                <img src={`/images/${imageFile}`} alt={`${alt}`} className='object-fill w-full max-w-[266px] h-full p-8 md:p-4 lg:p-8'/>
            </div>
        </a>
    ) : (
        <div className='flex-1 flex flex-col-reverse md:flex-row items-center gap-7 md:gap-14 p-5 sm:p-10 border-b-2 border-solid border-kaz-blue border-opacity-40 first:rounded-t-[38px] last:rounded-b-[38px] last:border-b-0'>
            <div className='flex-1 flex flex-col justify-center'>
                <h3 className='h3 gradient-text w-fit pb-5'>{heading}</h3>
                <p className='p'>{content}</p>
            </div>
            <div className='flex justify-center bg-[white] rounded-[36px] w-full h-2/4 md:w-[225px] md:h-[150px] lg:w-[330px] lg:h-[220px]'>
                <img src={`/images/${imageFile}`} alt={`${alt}`} className='object-fill w-full max-w-[266px] h-full p-8 md:p-4 lg:p-8'/>
            </div>
        </div>
    )
}

export default ProductItem
