import React from 'react'

const ServicesSummary = () => {
    return (
        <section className="container relative mb-[60px] md:mb-[90px]" id="services-summary">
            <h1 className='h1 text-center mb-6'>Our Services & Areas of Work</h1>
            <div className='flex flex-col-reverse md:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] mb-6 md:h-[300px]'>
                <div className='flex-1 flex flex-col justify-center'>
                    <div className='max-w-[620px] mx-auto p-6'>
                        <h3 className='h3 pb-5 gradient-text w-fit'>Revolutionising Education</h3>
                        <p className='p'>
                            Our mission is to revolutionise childhood education in Australia. Through advanced AI solutions,
                            we aim to enhance learning outcomes, making education an engaging, interactive, and effective journey.
                            Our AI tools transform traditional teaching, enabling educators to optimise their methods while providing
                            captivating experiences for children.
                        </p>
                    </div>
                </div>
                <div className='flex-1'>
                    <img className='w-full h-full max-h-[300px] object-cover rounded-t-[38px] md:rounded-r-[38px] md:rounded-tl-[0px]' src='/images/education-image.jpg' alt='Children Playing in a Daycare'/>
                </div>
            </div>
            <div className='flex flex-col md:flex-row bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] md:h-[300px]'>
                <div className='flex-1'>
                    <img className='w-full h-full max-h-[300px] object-cover rounded-t-[38px] md:rounded-l-[38px] md:rounded-tr-[0px]' src='/images/business-consulting-image.jpg' alt='Happy Workers in an Office'/>
                </div>
                <div className='flex-1 flex flex-col justify-center'>
                    <div className='max-w-[620px] mx-auto p-6'>
                        <h3 className='h3 pb-5 gradient-text w-fit'>Comprehensive AI Business Consulting</h3>
                        <p className='p'>
                            From startups to large enterprises, we offer AI consulting and tailored solutions
                            providing specialised expertise in the financial services sector.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesSummary
