import React from 'react'

const FinalCallToAction = () => {
    return (
        <section className="container relative mb-[160px]" id="final-call-to-action">
            <div className='z-[-1] w-[350px] h-[350px] xsm:w-[500px] xsm:h-[450px] md:w-[450px] md:h-[600px] lg:w-[700px] lg:h-[700px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-kaz-blue bg-opacity-35 rounded-full blur-[60px] xsm:blur-[80px] md:blur-[100px] lg:blur-[120px]' />
            <div className='flex flex-col items-center gap-10'>
                <h1 className='h1-large text-center'>Unlock the power of AI with Kazacos AI</h1>
                <p className='p-large text-center'>
                    Contact us today to discover how our innovative solutions can transform<br/>your business and drive success.
                </p>
                <a href="#contact-us">
                    <button className='btn btn-primary'>Make an Inquiry</button>
                </a>
            </div>
        </section>
    )
}

export default FinalCallToAction
