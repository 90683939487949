import React, { useEffect, useState } from 'react'
import GlowBackground from './components/GlowBackground'
import ScrollToTopButton from './components/ScrollToTopButton'
import Navbar from './components/Navbar'
import Welcome from './components/Welcome'
import Partners from './components/Partners'
import ServicesTitle from './components/ServicesTitle'
import ServicesSummary from './components/ServicesSummary'
import FinancialServices from './components/FinancialServices'
import MarketingServices from './components/MarketingServices'
import Toolkit from './components/Toolkit'
import Products from './components/Products'
import Testimonials from './components/Testimonials'
import Faq from './components/Faq'
import About from './components/About'
import Articles from './components/Articles'
import Team from './components/Team'
import FinalCallToAction from './components/FinalCallToAction'
import ContactUs from './components/ContactUs'
import Footer from './components/Footer'


import Particles, { initParticlesEngine } from '@tsparticles/react'
import { loadSlim } from '@tsparticles/slim'

import SmoothScroll from 'smooth-scroll'

import './index.css'

function App() {
    const [init, setInit] = useState(false)

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine)
        }).then(() => {
            setInit(true)
        })
    }, [])

    useEffect(() => {
        const scroll = new SmoothScroll('a[href*="#"]', {
            speed: 1000,
            speedAsDuration: true,
            easing: 'easeInOutCubic',
        })

        const stopSmoothScrollOnWheel = () => {
            scroll.cancelScroll()
        }

        window.addEventListener('wheel', stopSmoothScrollOnWheel)
    }, [])

    const particlesOptions = {
        background: {
            color: {
                value: "#071013",
            },
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "attract"
                },
                onClick: {
                    enable: true,
                    mode: "repulse"
                },
                resize: true,
            },
            modes: {
                attract: {
                    distance: 50,
                    duration: 0.4,
                    factor: 5,
                    maxSpeed: 1,
                    speed: 1
                },
                repulse: {
                    maxSpeed: 1,
                    distance: 60,
                    duration: 0.01,
                    speed: 0.05
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                enable: false,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce", // Make particles bounce off the viewport edges
                },
                random: false,
                speed: 2,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    area: 800,
                },
                value: 30,
            },
            opacity: {
                value: 0.3,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
            //   collisions: {
            //       enable: true,
            //       mode: "bounce",
            //   },
        },
        detectRetina: true,
    }

    return (
        <div className='flex flex-col items-center py-[20px] overflow-hidden'>
            {init && <Particles id="tsparticles" options={particlesOptions} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />}
            <GlowBackground />
            <ScrollToTopButton />
            <Navbar />
            <Welcome />
            <Partners />
            <ServicesTitle />
            <ServicesSummary />
            <FinancialServices />
            {/* <MarketingServices /> */}
            <Toolkit />
            <Products />
            {/* <Testimonials /> */}
            <Faq />
            <About />
            <Team />
            <Articles />
            <FinalCallToAction />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default App
