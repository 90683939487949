import React, { useState } from 'react'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    }

    const closeNavbar = () => {
        setIsOpen(false)
    }

    return (
        <div className='container z-[2]'>
            <nav className="bg-kaz-blue bg-opacity-3 pl-8 pr-4 py-3 flex items-center justify-between relative border-2 border-solid border-kaz-blue border-opacity-40 rounded-full">
                <a href="/" className="flex items-center mr-4 lg:mr-5">
                    <img src="/images/kazacosai-logo-gradient.png" alt="Kazacos AI Logo" className="w-[120px] h-auto" />
                </a>
                <button onClick={toggleNavbar} className="md:hidden p-2 rounded-md focus:outline-none focus:none">
                    <div className="w-6 h-6 flex flex-col justify-around items-center">
                        <span className={`block w-full h-0.5 bg-kaz-green transform transition-all duration-300 ease-in-out ${isOpen ? 'rotate-45 translate-y-2 translate-x-0' : 'rotate-0 translate-y-0 translate-x-0'}`}></span>
                        <span className={`block w-full h-0.5 bg-kaz-green transform transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-0' : 'opacity-100'}`}></span>
                        <span className={`block w-full h-0.5 bg-kaz-green transform transition-all duration-300 ease-in-out ${isOpen ? '-rotate-45 -translate-y-2 translate-x-0' : 'rotate-0 translate-y-0 translate-x-0'}`}></span>
                    </div>
                </button>
                <div className={`${isOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row md:items-center absolute md:relative top-full md:top-0 right-0 mt-[2px] md:mt-0 py-2 md:py-0 w-auto bg-[#0c191f] md:bg-opacity-0 z-50 animate-fadeIn md:animate-none`}>
                    <a href="#services-summary" onClick={closeNavbar} className="p-3 lg:p-5 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Services</a>
                    <a href="#products" onClick={closeNavbar} className="p-3 lg:p-5 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Products</a>
                    <a href="#partners" onClick={closeNavbar} className="p-3 lg:p-5 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Affiliates</a>
                    <a href="#about" onClick={closeNavbar} className="p-3 lg:p-5 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">About</a>
                    <a href="#articles" onClick={closeNavbar} className="p-3 lg:p-5 nav transition-[color] duration-200 ease-in-out hover:text-kaz-green">Articles</a>
                    <a href="#contact-us" onClick={closeNavbar} className='mx-3 my-1 md:my-0 md:ml-4 lg:ml-5'>
                        <button className='btn-small btn-primary md:hidden lg:inline-block'>Contact Us</button>
                        <button className='btn-icon btn-primary hidden md:inline-block lg:hidden'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#071013"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                        </button>
                    </a>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
