import React, { useState, useEffect } from 'react'
import Spline from '@splinetool/react-spline'

const ContactUsShape = () => {
    const [loading, setLoading] = useState(true)
    
    const handleLoad = (spline) => {
        setTimeout(() => {
            const camera = spline.findObjectById('64698d66-97e9-4897-8ffd-4636dba3a14c')
            const handleResize = () => {
                if(camera) {
                    camera.position.x = -10
                    if(window.innerWidth < 576) {
                        camera.scale.x = 2
                        camera.scale.y = 2
                        camera.scale.z = 2
                    } else if(window.innerWidth < 880) {
                        camera.scale.x = 1.7
                        camera.scale.y = 1.7
                        camera.scale.z = 1.7
                    } else if(window.innerWidth < 1024) {
                        camera.scale.x = 1.6
                        camera.scale.y = 1.6
                        camera.scale.z = 1.6
                    } else if(window.innerWidth < 1280) {
                        camera.scale.x = 1.3
                        camera.scale.y = 1.3
                        camera.scale.z = 1.3
                    } else {
                        camera.scale.x = 1
                        camera.scale.y = 1
                        camera.scale.z = 1
                    }
                }
            }
            window.addEventListener('resize', handleResize)
            handleResize()
            setLoading(false)
        }, 0)
    }

    return (
        <div className='relative w-full h-full min-h-[400px] md:min-h-[740px] flex items-center justify-center'>
            {loading && <div className='spinner'/>}
            <div className='absolute inset-0 w-full h-full z-[-1]'>
                <Spline
                    onLoad={handleLoad}
                    className='animate-fadeIn'
                    style={{pointerEvents: 'none', opacity: loading ? '0' : '1'}}
                    scene="https://prod.spline.design/1h31GRA9TknO-NRH/scene.splinecode"
                />
            </div>
        </div>
    )
}

export default ContactUsShape
