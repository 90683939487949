import React from 'react'

const FinancialServiceBox = ({ heading, content }) => {
    return (
        <div className='flex-1 bg-kaz-blue bg-opacity-3 border-2 border-solid border-kaz-blue border-opacity-40 rounded-[40px] p-6 min-w-[280px]'>
            <h3 className='h3 gradient-text pb-5'>{heading}</h3>
            <p className='p'>{content}</p>
        </div>     
    )
}

export default FinancialServiceBox
